import React from "react";

import UserList from '../components/user-management/list'
// import MerchantList from '../components/merchant-management/list'
// import MerchantAdd from '../components/merchant-management/add'
// import MerchantEdit from '../components/merchant-management/edit'
// import RoleList from '../components/role-management/list'
// import StaffList from '../components/staff-management/list'
// import AddNewRole from '../components/role-management/add'
// import StaffNewRole from '../components/staff-management/add'
// import EditNewRole from '../components/role-management/edit'
// import ViewNewRole from '../components/role-management/view'
// import ViewNewStaff from '../components/staff-management/view'
// import EditNewStaff from '../components/staff-management/edit'
import EditNewUser from '../components/user-management/edit'
import GameList from "../components/game-management/list";
import AddNewGame from "../components/game-management/add";
import EditNewGame from "../components/game-management/edit";

// Dashboard

import ProductManagement from "../dashboard/ProductManagement";

import ViewNewUser from "../components/user-management/view";
import ViewNewMerchant from "../components/merchant-management/view";
import TransactionHistory from "../components/transaction-management/transactionHistory";
import ViewWallets from "../components/user-management/viewWallets";
import ViewBankDetails from "../components/user-management/ViewBanks";
import ViewUserWalletDetails from "../components/user-management/viewWalletDetails";
import Crash_Bet from "../components/GameBetHistory/Crash_Bet";
import CrashHistory from "../components/crashHistory/CrashHistory";
import ViewGameDetails from "../components/game-management/view";


// game settings
import GameSettingList from '../components/game-setting/list'
import AddNewGameSetting from "../components/game-setting/add";
import ViewGameSetting from "../components/game-setting/view";
import EditGameSetting from "../components/game-setting/edit";


// currency management

import CurrencyListTable from "../components/currency-management/list";
import AddCurrency from "../components/currency-management/add";
import EditCurrency from "../components/currency-management/edit";
import ViewCurrencyDetails from "../components/currency-management/view";
import PrivacyPolicy from "../components/static-pages/privacy-policy";
import TermsAndConditions from "../components/static-pages/terms-and-conditions";
import AboutUs from "../components/static-pages/about-us";
import BetStatics from "../components/dashboard/bet-statistics";
import EventManagement from "../dashboard/EventManagement";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import TransactionMetrics from "../components/dashboard/transaction-metrics";
import UserMetrics from "../components/dashboard/user-metrics";
import FAQList from "../components/static-pages/faq/list";
import AddNewFAQ from "../components/static-pages/faq/add";
import EditFAQ from "../components/static-pages/faq/edit";
import ViewFAQ from "../components/static-pages/faq/view";
import BannerList from "../components/banner-management/list";
import AddNewBanner from "../components/banner-management/add";
import EditBanner from "../components/banner-management/edit";
import ViewBanner from "../components/banner-management/view";
import GameMetrics from "../components/dashboard/game-statistics";
import CricketHistory from "../components/sports/cricket-sports";
import CricketSportBetList from "../components/sports-record/cricket-sports/list";
import ReferralSetting from "../components/referral-management/referral-setting";
import GameCategoryList from "../components/game-management/category-list";
import EditGameCategory from "../components/game-management/category-edit";
import LotteryList from "../components/lottery-management/list";
import TicketList from "../components/lottery-management/ticket-list";
import AddNewLottery from "../components/lottery-management/add";
import ViewLottery from "../components/lottery-management/view";
import EditLottery from "../components/lottery-management/edit";
import MakeWinner from "../components/lottery-management/makeWinner";
import LotteryStatistics from "../components/dashboard/lottery-statistics";
import AddNewSettings from "../components/platform-settings/add";
import PlatformSettingsList from "../components/platform-settings/list";
import ViewSettings from "../components/platform-settings/view";
import EditSettings from "../components/platform-settings/edit";
import ViewProfile from "../layouts/ViewProfile";
const protectedRoutes = [
  // platform specific routes
  { path: "view-profile", element: <ViewProfile /> },
  
  { path: "platform-settings/add", element: <AddNewSettings /> },
  { path: "platform-settings/list", element: <PlatformSettingsList /> },
  { path: "platform-settings/edit", element: <EditSettings /> },
  { path: "platform-settings/view", element: <ViewSettings /> },


  // currency route
  { path: "lottery-list", element: <LotteryList /> },
  { path: "ticket-list", element: <TicketList /> },
  { path: "lottery/add", element: <AddNewLottery /> },
  { path: "lottery/edit", element: <EditLottery /> },
  { path: "lottery/view", element: <ViewLottery /> },
  { path: "lottery/make-winner", element: <MakeWinner /> },



  




  { path: "currency", element: <CurrencyListTable /> },
  { path: "currency/add", element: <AddCurrency /> },
 
  { path: "currency/view", element: <ViewCurrencyDetails /> },
  { path: "currency/edit", element: <EditCurrency /> },

 // game settings route
  
 { path: "game-setting", element: <GameSettingList /> },
 { path: "game-setting/add", element: <AddNewGameSetting /> },

 { path: "game-setting/view", element: <ViewGameSetting /> },
 { path: "game-setting/edit", element: <EditGameSetting /> },
  // Game_Bet History

   {path:"bet-history",element:<Crash_Bet/>},
   {path:"crash-history",element:<CrashHistory />},
  { path: "transaction-history", element: <TransactionHistory  transactionType={''} type={''}/> },
  { path: "pay-in/transaction", element: <TransactionHistory transactionType={'credit'} type={'wallet'} /> },
  { path: "pay-out/transaction", element: <TransactionHistory transactionType={'debit'} type={'wallet'}/> },

  // { path: "dashboard", element: <ProductManagement /> },
  { path: "user-management", element: <UserList /> },
  { path: "user-management/edit", element: <EditNewUser /> },
  { path: "user-management/view", element: <ViewNewUser /> },
  { path: "user-management/view-wallet", element: <ViewWallets /> },
  { path: "user-management/view-wallet/view", element: <ViewUserWalletDetails /> },
  { path: "user-management/view-bank", element: <ViewBankDetails /> },

  // wallet-management/view

  // { path: "staff-management", element: <StaffList /> },
  // { path: "role-management", element: <RoleList /> },

  { path: "game-management-category", element: <GameCategoryList /> },
  { path: "game-management-category/edit", element: < EditGameCategory/> },

  // EditGameCategory

  { path: "game-management", element: <GameList /> },
  { path: "game-management/add", element: <AddNewGame /> },
  { path: "game-management/edit", element: <EditNewGame /> },
  { path: "game-management/view", element: <ViewGameDetails /> },

  // { path: "role-management/add", element: <AddNewRole /> },
  // { path: "staff-management/add", element: <StaffNewRole /> },
  // { path: "staff-management/edit", element: <EditNewStaff /> },
  // { path: "role-management/edit", element: <EditNewRole /> },
  // { path: "role-management/view", element: <ViewNewRole /> },
  // { path: "staff-management/view", element: <ViewNewStaff /> },
  // { path: "merchant-management", element: <MerchantList /> },
  // { path: "merchant-management/add", element: <MerchantAdd /> },
  // { path: "merchant-management/edit", element: <MerchantEdit /> },
  // { path: "merchant-management/view", element: <ViewNewMerchant /> },

 
  { path: "privacy-policy", element: <PrivacyPolicy  /> },
  { path: "terms-and-conditions", element: <TermsAndConditions /> },
  { path: "about-us", element: <AboutUs /> },
  { path: "faq", element: <FAQList /> },
  { path: "faq/add", element: <AddNewFAQ/> },
  { path: "faq/edit", element: <EditFAQ /> },
  { path: "faq/view", element: <ViewFAQ /> },

  { path: "banner", element: <BannerList /> },
  // { path: "banner/add", element: <AddNewBanner/> },
  { path: "banner/edit", element: <EditBanner /> },
  { path: "banner/view", element: <ViewBanner /> },

  { path: "bet-statics", element: <BetStatics /> },
  { path: "financial-metrics", element: <TransactionMetrics /> },
  {path:"user-metrics", element: <UserMetrics /> },
  { path: "game-metrics", element: <GameMetrics /> },
  { path: "lottery-metrics", element: <LotteryStatistics /> },

  
  { path: 'cricket-sports-history', element: <CricketHistory /> },
  
  // sport bet list

  { path: 'cricket-sports-betList', element: <CricketSportBetList /> },

  

  // referral
  { path: 'referral-setting', element: <ReferralSetting /> }

  
  

  // { path: "dashboard/events", element: <EventManagement /> },
  // { path: "dashboard/sales", element: <SalesMonitoring/> },
  // { path: "dashboard/analytics", element: <WebsiteAnalytics /> },
  // { path: "dashboard/crypto", element: <Cryptocurrency /> },
  // { path: "dashboard/helpdesk", element: <HelpdeskService /> },
  // { path: "dashboard/storage", element: <StorageManagement /> },
  // { path: "dashboard/product", element: <ProductManagement /> },
  // { path: "apps/gallery-music", element: <GalleryMusic /> },
  // { path: "apps/gallery-video", element: <GalleryVideo /> },
  // { path: "apps/tasks", element: <Tasks /> },
  // { path: "apps/contacts", element: <Contacts /> },
  // { path: "apps/chat", element: <Chat /> },
  // { path: "apps/calendar", element: <AppCalendar /> },
  // { path: "apps/email", element: <Email /> },
  // { path: "apps/file-manager", element: <FileManager /> },
  // { path: "pages/pricing", element: <Pricing /> },
  // { path: "pages/faq", element: <Faq /> },
  // { path: "pages/profile", element: <Profile /> },
  // { path: "pages/people", element: <People /> },
  // { path: "pages/activity", element: <Activity /> },
  // { path: "pages/events", element: <Events /> },
  // { path: "pages/settings", element: <Settings /> },
  // { path: "docs/layout/grid", element: <LayoutGrid /> },
  // { path: "docs/layout/columns", element: <LayoutColumns /> },
  // { path: "docs/layout/gutters", element: <LayoutGutters /> },
  // { path: "docs/com/accordions", element: <Accordions /> },
  // { path: "docs/com/alerts", element: <Alerts /> },
  // { path: "docs/com/avatars", element: <Avatars /> },
  // { path: "docs/com/badge", element: <Badges /> },
  // { path: "docs/com/breadcrumbs", element: <Breadcrumbs /> },
  // { path: "docs/com/buttons", element: <Buttons /> },
  // { path: "docs/com/cards", element: <Cards /> },
  // { path: "docs/com/carousel", element: <Carousels /> },
  // { path: "docs/com/dropdown", element: <Dropdowns /> },
  // { path: "docs/com/images", element: <Images /> },
  // { path: "docs/com/listgroup", element: <Listgroup /> },
  // { path: "docs/com/markers", element: <Markers /> },
  // { path: "docs/com/modal", element: <Modals /> },
  // { path: "docs/com/navtabs", element: <NavTabs /> },
  // { path: "docs/com/offcanvas", element: <OffCanvas /> },
  // { path: "docs/com/pagination", element: <Paginations /> },
  // { path: "docs/com/placeholders", element: <Placeholders /> },
  // { path: "docs/com/popovers", element: <Popovers /> },
  // { path: "docs/com/progress", element: <Progress /> },
  // { path: "docs/com/spinners", element: <Spinners /> },
  // { path: "docs/com/toasts", element: <Toasts /> },
  // { path: "docs/com/tooltips", element: <Tooltips /> },
  // { path: "docs/com/tables", element: <Tables /> },
  // { path: "docs/form/elements", element: <FormElements /> },
  // { path: "docs/form/selects", element: <FormSelects /> },
  // { path: "docs/form/checksradios", element: <FormChecksRadios /> },
  // { path: "docs/form/range", element: <FormRange /> },
  // { path: "docs/form/pickers", element: <FormPickers /> },
  // { path: "docs/form/layouts", element: <FormLayouts /> },
  // { path: "docs/chart/apex", element: <ApexCharts /> },
  // { path: "docs/chart/chartjs", element: <ChartJs /> },
  // { path: "docs/map/leaflet", element: <MapLeaflet /> },
  // { path: "docs/map/vector", element: <MapVector /> },
  // { path: "docs/icon/remix", element: <IconRemix /> },
  // { path: "docs/icon/feather", element: <IconFeather /> },
  // { path: "docs/util/background", element: <UtilBackground /> },
  // { path: "docs/util/border", element: <UtilBorder /> },
  // { path: "docs/util/colors", element: <UtilColors /> },
  // { path: "docs/util/divider", element: <UtilDivider /> },
  // { path: "docs/util/flex", element: <UtilFlex /> },
  // { path: "docs/util/sizing", element: <UtilSizing /> },
  // { path: "docs/util/spacing", element: <UtilSpacing /> },
  // { path: "docs/util/opacity", element: <UtilOpacity /> },
  // { path: "docs/util/position", element: <UtilPosition /> },
  // { path: "docs/util/typography", element: <UtilTypography /> },
  // { path: "docs/util/shadows", element: <UtilShadows /> },
  // { path: "docs/util/extras", element: <UtilExtras /> }
]

export default protectedRoutes;