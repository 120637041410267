import { BehaviorSubject } from "rxjs";
import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Link, useNavigate } from "react-router-dom";
import createAxiosInstance from "../api.instance";
const AuthService = () => {
const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const navigate = useNavigate()
  // Initialize a BehaviorSubject to hold the authentication state
const axiosInstance = createAxiosInstance(bearerToken);
const currentUserSubject = new BehaviorSubject(null);
  // BehaviorSubject to hold the authentication state
  const isAuthenticated$ = new BehaviorSubject(false);

  const BASE_URL = process.env.REACT_APP_API_URL;
  const BASIC_AUTH = 'Basic a3JpcGFiZXQ6a3JpcGFiZXRAMTIzNDU=';

  const register = async (requestData) => {
   return await axiosInstance.post(`${BASE_URL}/admin/auth/register`, requestData)
   .then(response => {
     console.log('Registration successful', response);
     return response.data; // Return response data
   })
   .catch(error => {
     console.error('Registration failed:', error);
    //  return error.response.data; // Throw error to be caught by the caller
   });
  
  };

  

  // Simulate a logout function
  const logout = () => {
    console.log("hete")
    // Clear the current user from local storage and the BehaviorSubject
    localStorage.clear();
    navigate('/signin')
  };

  // Get the current user
  const getCurrentUser = () => {
    return currentUserSubject.value;
  };
   // Function to check if user is authenticated
   const isAuthenticated = () => {
    return isAuthenticated$.getValue();
  };

  const otpverify = async (otp, requestData) => {
    return await axiosInstance.post(`${BASE_URL}/auth/verify-otp/${otp}`, requestData,  {
      headers: {
        'Authorization': BASIC_AUTH
      }
    })
    .then(response => {
      console.log('Registration successful', response);
      return response.data; // Return response data
    })
    .catch(error => {
      console.error('Registration failed:', error.response.data);
      return error.response.data; // Throw error to be caught by the caller
    });
  }

  const resendOtp = async (otp, requestData) => {
    return await axiosInstance.post(`${BASE_URL}/auth/resend-email-otp/${otp}`, requestData,  {
      headers: {
        'Authorization': BASIC_AUTH
      }
    })
    .then(response => {
      console.log('Registration successful', response);
      return response.data; // Return response data
    })
    .catch(error => {
      console.error('Registration failed:', error.response.data);
      return error.response.data; // Throw error to be caught by the caller
    });
  }

  // Simulate a login function
  // const login = async (requestData) => {
  //   try {
  //     const response = await axios.post(`${BASE_URL}/admin/auth/login`, requestData, {
  //       headers: {
  //         'Authorization': BASIC_AUTH
  //       }
  //     });
  //     // console.log('Login successful', response.data);
  //     return response.data; // Return response data
  //   } catch (error) {
  //     // console.error('Login failed:', error.response.data);
  //     return error.response.data; // Throw error to be caught by the caller
  //   }
  // };

  // merchant/auth/login  ----merchant login api
  const login = async (requestData) => {
    try {
      const response = await axios.post(`${BASE_URL}/merchant/auth/login`, requestData, {
        headers: {
          'Authorization': BASIC_AUTH
        }
      });
      // console.log('Login successful', response.data);
      return response.data; // Return response data
    } catch (error) {
      // console.error('Login failed:', error.response.data);
      return error.response.data; // Throw error to be caught by the caller
    }
  };
  // Simulate a login function
  const forgotPassword = async (requestData) => {
    try {
      const response = await axios.post(`${BASE_URL}/admin/auth/forgot-password`, requestData, {
        headers: {
          'Authorization': BASIC_AUTH
        }
      });
      console.log('Login successful', response);
      return response.data; // Return response data
    } catch (error) {
      console.error('Login failed:', error);
      return error.response.data; // Throw error to be caught by the caller
    }
   
  };

  // Simulate a login function
  const resetPassword = async (requestData) => {
   

    return await axios.post(`${BASE_URL}/admin/auth/reset-password?token=${requestData.token}`, {password: requestData.password}, {
      headers: {
        'Authorization': BASIC_AUTH
      }  
    })
    .then(response => {
      console.log('Registration successful', response);
      return response.data; // Return response data
    })
    .catch(error => {
      console.error('Registration failed:', error.response.data);
      return error.response.data; // Throw error to be caught by the caller
    });
  };
  const getAdminData = () => {
    return JSON.parse(localStorage.getItem("user"))
  }

  const getLoginData = async () => {
   
    return await axiosInstance
      .get(`/merchant/auth/profile`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response.data);
        localStorage.setItem("user", JSON.stringify(response.data.data))
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  }

  // getMetaData

  const getMetaData = async () => {
   
    return await axios
      .get(`${BASE_URL}/merchant/auth/profile`,  {  headers: {
        'Authorization': BASIC_AUTH
      }  })
      .then((response) => {
        console.log("Registration =============", response.data);
        // localStorage.setItem("user", JSON.stringify(response.data.data))
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  }

 const checkAccess = (moduleId, type) =>{
    // var data = getAdminData().role;
    // var loginDetails = getAdminData();
    // // console.log('data-----===>>122==', data)
    // if (data && loginDetails.type != 'superadmin') {
    //     for (let i = 0; i < data.resource.length; i++) {
    //         // console.log("moduleId", moduleId)
    //         // console.log("moduleId", moduleId)
    //          if (data.resource[i].moduleId ==moduleId) {
    //             return data.resource[i].permissions.includes(type) ? true : false
    //         }
    //     }
    // } else {
    //     return true;
   // }
   return true;
}

  // Observable to subscribe to changes in authentication state
  const currentUser$ = currentUserSubject.asObservable();

  return {
    getMetaData,
    logout,
    getCurrentUser,
    currentUser$,
    isAuthenticated,
    isAuthenticated$,
    register,
    login,
    otpverify,
    resendOtp,
    resetPassword,
    forgotPassword,
    getLoginData,
    checkAccess
  };
};

export default AuthService;
