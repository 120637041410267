import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Table
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import '../common/table.css'
import Header from './Header';
import Sidebar from './Sidebar';
import { formatCurrency } from '../helper/CurrencyFormate';
import { Link } from 'react-router-dom';
import AuthService from '../service/api/auth.service';
import { BorderSpinner } from '../common/Spinner';
export default function ViewProfile() {
    const authService = AuthService();
    const [profileData, setProfileData] = useState(null);
    const [balancesTableData, setBalancesTableData] = useState([]);
    const [profileDataLoading, setProfileDataLoading] = useState(true);
    const theme = useSelector((state) => state.ui.skin);
    let user = localStorage.getItem("user")
    user = JSON.parse(user);
    useEffect(() => {
        const getProfileData = async () => {

            try {
                const res = await authService.getLoginData();
                if (res.code === 200) {

                    user = res.data;
                    // console.log(user, "user loaded")

                    const groupedData = user?.balances?.reduce((acc, item) => {
                        const { currencyCodes, name, currencySymbols } = item.currencyId;

                        if (!acc[currencyCodes]) {
                            acc[currencyCodes] = {
                                currency: {
                                    name,
                                    code: currencyCodes,
                                    symbol: currencySymbols,
                                },
                                balances: {
                                    deposit: 0,
                                    bonus: 0,
                                    winnings: 0,
                                },
                            };
                        }

                        // Map the `type` field to the corresponding balance type
                        if (item.type === 0) {
                            acc[currencyCodes].balances.deposit = item.balance;
                        } else if (item.type === 2) {
                            acc[currencyCodes].balances.bonus = item.balance;
                        } else if (item.type === 1) {
                            acc[currencyCodes].balances.winnings = item.balance;
                        }

                        return acc;
                    }, {});

                    setBalancesTableData(Object.values(groupedData));
                    setProfileData(user);

                }

            } catch (error) {
                console.error(error);
            } finally {
                setProfileDataLoading(false);
            }
        }
        getProfileData();

    }, []);

    // useEffect(() => {
    //     authService.getLoginData()
    //   }, []);
    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <section >
                    <Container className="py-2">
                        {/* Breadcrumb */}
                        <Row>
                            <Col>
                                <Breadcrumb>
                                    <BreadcrumbItem >
                                        <Link to='/'>Dashboard
                                        </Link>
                                    </BreadcrumbItem>
                                    {/* <BreadcrumbItem href="#">User</BreadcrumbItem> */}
                                    <BreadcrumbItem active>User Profile</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        {profileDataLoading ? <>
                            <div className="d-flex mt-5 justify-content-center align-content-center">
                                <BorderSpinner />
                            </div>
                        </> : (<Row>
                            {/* Left Column */}
                            <Col lg="4">
                                <Card className="mb-4">
                                    <Card.Body className="text-center">
                                        <Card.Img
                                            variant="top"
                                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                                            alt="avatar"
                                            className="rounded-circle mb-3"
                                            style={{ width: '150px' }}
                                        />
                                        <Card.Title>{profileData?.name}</Card.Title>
                                        <Card.Text>{profileData?.role.toUpperCase()}</Card.Text>
                                        <Card.Text className="text-muted">{profileData?.domain}</Card.Text>
                                        {/* <div className="d-flex justify-content-center">
                                            <Button variant="primary" className="me-2">Follow</Button>
                                            <Button variant="outline-secondary">Message</Button>
                                        </div> */}
                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* Right Column */}
                            <Col lg="8">
                                {/* User Details */}
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Row className="mb-3">
                                            <Col sm="3"><strong>Full Name</strong></Col>
                                            <Col sm="9">{profileData?.name}</Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="3"><strong>Email</strong></Col>
                                            <Col sm="9">{profileData?.email}</Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="3"><strong>Phone</strong></Col>
                                            <Col sm="9">{profileData?.phone}</Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="3"><strong>Support Number</strong></Col>
                                            <Col sm="9">{profileData?.supportNumber}</Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="3"><strong>Deposit Number</strong></Col>
                                            <Col sm="9">{profileData?.depositeNumber}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm="3"><strong>Status</strong></Col>
                                            <Col sm="9">{profileData?.status == 1 ? 'Active' : 'Inactive'}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* Balances Table */}
                                <Card>
                                    <Card.Body>
                                        <Col md={12} className="mt-5">
                                            <h5>Balances</h5>
                                            <Table className={`table-bordered  table-centered ${theme === 'dark' ? 'customTable' : ''} mb-0`}>
                                                <thead>
                                                    <tr>
                                                        <th>Currency</th>
                                                        <th>Deposit</th>
                                                        {/* <th>Bonus</th>
                                                        <th>Winnings</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {balancesTableData?.map((data, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {data.currency.name} ({data.currency.code})
                                                            </td>
                                                            <td>{formatCurrency(data.balances.deposit, data.currency.code)}</td>
                                                            {/* <td>{formatCurrency(data.balances.bonus, data.currency.code)}</td>
                                                            <td>{formatCurrency(data.balances.winnings, data.currency.code)}</td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>)}
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


