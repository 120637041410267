import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../assets/img/img1.jpg";
import notification from "../data/Notification";
import AuthService from "../service/api/auth.service";
import { Badge, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useSkinSwitcher from "../common/skin";
import { logoutSuccess } from "../Redux/Action/auth.action";
import { switchSkin } from "../Redux/Action";
import { formatCurrency } from "../helper/CurrencyFormate";
export default function Header({ onSkin }) {


  // const reduxSkin = useSelector((state) => state.ui.skin);  // Assuming 'ui' is the key for skinReducer


  const authService = AuthService()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { skin, setSkin } = useSkinSwitcher();
  const [userDetail, setUserDetail] = useState()
  const [balancesTableData, setBalancesTableData] = useState([]);

  // let user = localStorage.getItem("user")
  // user = JSON.parse(user);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (user) {
      const groupedData = user?.balances?.reduce((acc, item) => {
        const { currencyCodes, name, currencySymbols } = item.currencyId;

        if (!acc[currencyCodes]) {
          acc[currencyCodes] = {
            currency: {
              name,
              code: currencyCodes,
              symbol: currencySymbols,
            },
            balances: {
              deposit: 0,
              bonus: 0,
              winnings: 0,
            },
          };
        }

        // Map the `type` field to the corresponding balance type
        if (item.type === 0) {
          acc[currencyCodes].balances.deposit = item.balance;
        } else if (item.type === 2) {
          acc[currencyCodes].balances.bonus = item.balance;
        } else if (item.type === 1) {
          acc[currencyCodes].balances.winnings = item.balance;
        }

        return acc;
      }, {});

      setBalancesTableData(Object.values(groupedData));
      setUserDetail(user)
    }
  }, [user]);

  const handleSwitchSkin = (newSkin) => {
    dispatch(switchSkin(newSkin));
  }
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    console.log("isOffset", isOffset)
    // document.body.classList.add("sidebar-show");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  function NotificationList() {
    const notiList = notification.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div className={(item.status === "online") ? "avatar online" : "avatar"}>{item.avatar}</div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      )
    });

    return (
      <ul className="list-group">
        {notiList}
      </ul>
    );
  }

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem('skin-mode', skin);
      //  console.log(skin,'hello mode')
      setSkin(skin)
      handleSwitchSkin(skin)
      // onSkin(skin);

    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem('skin-mode');
      // console.log(skin,'hello mode')
      setSkin('');
      handleSwitchSkin(skin)
      // onSkin('');
    }

  };

  const sidebarSkin = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== "default") {
      HTMLTag.setAttribute("data-sidebar", skin);
      localStorage.setItem("sidebar-skin", skin);
    } else {
      localStorage.removeItem("sidebar-skin", skin);
    }
  };



  return (
    <div className="header-main px-3 px-lg-4 justify-content-between">

      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      {/* <div className="form-search me-auto">
        <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i>
      </div> */}

      <div className="d-flex">
        {/* {skin} */}
        <div className="d-flex gap-3 " role="group">
          <span
            className={`pt-2 fw-bold ${skin == "dark"?'text-white':'text-muted'} `}
            style={{
              fontSize: '1rem',
              whiteSpace: 'nowrap', // Ensures the text does not wrap
            }}
          >
            Available Balance: {formatCurrency(balancesTableData[0]?.balances?.deposit ||0, balancesTableData[0]?.currency?.code || "INR")}
          </span>
          <button onClick={skinMode} className={`${skin == "dark" ? "btn btn-outline-primary " : "btn btn-outline-primary"} text-dark border text-center rounded-pill`}> <i className="ri-sun-line mx-1 m-1 "></i> Light </button>
          <button onClick={skinMode} className={`${skin == "dark" ? "btn" : "btn btn-outline-primary"}  rounded-pill text-dark`}><i className="ri-moon-line mx-1 mt-1"></i>Dark</button>
        </div>


        {/* <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Skin Mode</label>
          <nav className="nav nav-skin">
          </nav>
          <hr />
          <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link onClick={sidebarSkin} className={!(localStorage.getItem("sidebar-skin")) ? "nav-link active" : "nav-link"}>Default</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "prime") ? "nav-link active" : "nav-link"}>Prime</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "dark") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown> */}

        {/* <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <small>3</small><i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer"><Link to="#">Show all Notifications</Link></div>
        </Dropdown.Menu>
      </Dropdown> */}

        <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
          <Dropdown.Toggle as={CustomToggle}>
            <div className="avatar online">
              <img src={userAvatar} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-10-f">
            <div className="dropdown-menu-body">
              <div className="avatar avatar-xl online mb-3"><img src={userAvatar} alt="" /></div>
              <h5 className="mb-1 text-dark fw-semibold">{userDetail && Object.values(userDetail).length > 0 ? userDetail.name : ""}</h5>
              {/* <p className="fs-sm text-secondary">Premium Member</p> */}

              <nav className="nav">
                {/* <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link> */}
                <Link to="/view-profile"><i className="ri-profile-line"></i> View Profile</Link>
              </nav>
              <hr />
              <nav className="nav">
                {/* <Link to=""><i className="ri-question-line"></i> Help Center</Link> */}
                {/* <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link> */}
                <Button onClick={() => {
                  dispatch(logoutSuccess());
                  console.log("logout check")
                  authService.logout();
                  navigate('/signin')
                }}><i className="ri-logout-box-r-line"></i> Log Out</Button>
              </nav>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}